import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="services-area page-section scroll-to-page" id="services">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-stream"></i> Services
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                </div>

                <div className="services-items">
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-code"></i>
                        <h2>Web Development</h2>
                        <p>I build web applications go live with React, Python, Laravel/PHP, Node.js and Javascript</p>
                        {/* <span className="projects">126 Projects</span> */}
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-code"></i>
                        <h2>Mobile Development</h2>
                        <p>I build mobile applications go live with React, Python, Laravel/PHP, Node.js and Javascript</p>
                        {/* <span className="projects">126 Projects</span> */}
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-bezier-curve"></i>
                        <h2>SEO</h2>
                        <p>Increase the traffic for your website with SEO optimized</p>
                        {/* <span className="projects">8 Projects</span> */}
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-bezier-curve"></i>
                        <h2>Analytics and Data Analysis</h2>
                        <p>Proficiency in using analytics tools (e.g., Google Analytics) to track campaign performance, understand user behavior, and make data-driven decisions.</p>
                        {/* <span className="projects">24 Projects</span> */}
                    </div>
                </div>    
            </div>
        </div>
    </section>
  )
}
