import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="about-area page-section scroll-to-page" id="about">
        <div className="custom-container">
            <div className="about-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="lar la-user"></i> About
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Senior Software Engineer @ RISE HR LLC</h1>
                </div>
                <p className="scroll-animation" data-aos='fade-up'>Results-driven Senior Software Engineer with over 8 years of experience in designing and implementing scalable software solutions. Proficient in modern programming languages including Java, Python, and JavaScript, with a strong focus on cloud computing and microservices architecture. Proven track record of leading cross-functional teams to deliver high-impact projects, such as a cloud migration that saved $500,000 annually. Adept at collaborating with stakeholders to translate business requirements into technical specifications while ensuring optimal performance and security. Seeking to leverage my technical expertise and leadership skills to drive innovation in a dynamic tech environment.</p>
            </div>
        </div>
    </section>
  )
}
