import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i> Resume
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Work Experience</h1>
                </div>

                <div className="resume-timeline">
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">02/2024 - Present</span>
                        <h2>Senior Software Engineer</h2>
                        <p>RISE HR LLC</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">02/2020 - 12/2023</span>
                        <h2>Senior Software Engineer</h2>
                        <p>R17 Ventures AG</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
  )
}
